import axios from "axios";
import {setToken, getToken} from "@/utils/auth";
import {Message} from "element-ui";
import router from "@/router";
import store from "@/store";
import cache from "@/plugins/cache";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 20000,
});

http.interceptors.request.use((config) => {
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
  if (getToken("SA-TOKEN")) {
    config.headers["SA-TOKEN"] = getToken("SA-TOKEN");
  }
  if (
    !isRepeatSubmit &&
    (config.method === "post" || config.method === "put")
  ) {
    const requestObj = {
      url: config.url,
      data:
        typeof config.data === "object"
          ? JSON.stringify(config.data)
          : config.data,
      time: new Date().getTime(),
    };
    const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
    if (requestSize >= limitSize) {
      console.warn(
        `[${config.url}]: ` +
        "请求数据大小超出允许的5M限制，无法进行防重复提交验证。"
      );
      return config;
    }
    const sessionObj = cache.session.getJSON("sessionObj");
    if (sessionObj === undefined || sessionObj === null || sessionObj === "") {
      cache.session.setJSON("sessionObj", requestObj);
    } else {
      const s_url = sessionObj.url; // 请求地址
      const s_data = sessionObj.data; // 请求数据
      const s_time = sessionObj.time; // 请求时间
      const interval = 5000; // 间隔时间(ms)，小于此时间视为重复提交
      if (
        s_data === requestObj.data &&
        requestObj.time - s_time < interval &&
        s_url === requestObj.url
      ) {
        const message = "数据正在处理，请勿重复提交";
        console.warn(`[${s_url}]: ` + message);
        return Promise.reject(new Error(message));
      } else {
        cache.session.setJSON("sessionObj", requestObj);
      }
    }
  }
  return config;
});

http.interceptors.response.use(
  (resp) => {
    if (resp.data.code === 500) {
      Message.error(resp.data.msg);
      return Promise.reject(resp.data.msg);
    }
    return resp.data;
  },
  (err) => {
    const error = err.response;
    if (error.status === 401) {
      Message.error("登录已超时，请重新登录!");
      store.dispatch("user/Logout").then(() => {
        router.replace({name: "Login"});
      });
    } else if (error.status === 403) {
      Message.error("权限不足，请通知管理员！");
    } else if (error.status === 400) {
      Message.error("系统未知错误，请反馈给管理员！");
    } else if (error.status === 500) {
      // Message.error(error.error);
      Message.error("数据出错！");

    }
  }
);

export default http;
