<template>
  <div class="layout-page">
    <el-container>
      <el-aside style="height: 100%" width="auto">
        <LayoutMenu/>
      </el-aside>
      <el-container>
        <el-header
          class="flex items-center"
          style="box-shadow: 0 4px 4px rgba(0, 21, 41, 0.08)"
        >
          <LayoutHeader
          />
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import LayoutMenu from "./components/Menu/index.vue";
import LayoutHeader from "./components/Header/index.vue";
import {Message} from "element-ui";

export default {
  components: {
    LayoutMenu,
    LayoutHeader,
  },
  data() {
    return {};
  },

  computed: {
    isCollapse() {
      return this.$store.state.config.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-page,
.el-container {
  height: 100%;
  width: 100%;
}

.el-main {
  background: rgba(245, 245, 245, 1);
  overflow: auto;
  padding: 20rem;
}
</style>
