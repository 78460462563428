import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/user",
    component: Layout,
    name: "user",
    meta: {
      name: "用户",
    },
    children: [
      {
        path: "profile",
        meta: {
          name: "个人信息",
        },
        name: "userProfile",
        component: () => import("@/views/system/admin/profile"),
      },
    ],
  },
  {
    path: "/user",
    name: "Layout",
    component: () => import("@/layout/index.vue"),
    redirect: "Home",
    children: [
      {
        name: "Home",
        path: "/home",
        meta: {
          name: "首页",
        },
        component: () => import("@/views/home"),
      },
    ],
  },
  {
    path: "/tool/gen-edit",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "index/:tableId(\\d+)",
        component: () => import("@/views/system/tools/generator/editTable"),
        name: "GenEdit",
        meta: {title: "修改生成配置", activeMenu: "/tool/gen"},
      },
    ],
  },
  {
    path: "/system/dict-data",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "index/:dictId(\\d+)",
        component: () => import("@/views/system/config/dict/data"),
        name: "DataDict",
        meta: {title: "字典数据", activeMenu: "/system/dict"},
      },
    ],
  },
];

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_CONTEXT_PATH,
  routes,
});

export default router;
