import {
  loginApi,
  logoutApi,
  getInfoApi,
  getAllocationMenuApi,
} from "@/service/system/permission";
import { setToken, removeToken } from "@/utils/auth";
import { Message } from "element-ui";
import Layout from "@/layout";
import ParentLayout from "@/components/ParentView";
import router from "@/router";

export default {
  namespaced: true,
  state: () => {
    return {
      token: "",
      nickname: "",
      avatar: "",
      roles: [],
      userInfo:{},
      permissions: [],
      siderbarRouters: [],
      router: [],
    };
  },
  getters: {
    isAdmin() {}, // -> getters['account/isAdmin']
    routers(state) {
      return state.router;
    },
  },
  actions: {
    Login(context, loginForm) {
      return new Promise((resolve, reject) => {
        // 对用户进行登录
        loginApi(loginForm)
          .then((resp) => {
            setToken(resp.data.tokenValue);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    GetUserInfo(context) {
      return new Promise((resolve, reject) => {
        getInfoApi()
          .then((resp) => {
            const userInfo = resp.data.user;
            const roles = resp.data.roles;
            const perm = resp.data.perm;
            // console.log(resp);
            context.commit("SET_USERINFO", userInfo);
            context.commit("SET_NICKNAME", userInfo.nickname);

            context.commit("SET_AVATAR", userInfo.avatar);
            context.commit("SET_ROLE", roles);
            context.commit("SET_PERMISSION", perm);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    GeneratorMenu(context) {
      return new Promise((resolve, reject) => {
        getAllocationMenuApi()
          .then((resp) => {
            const router = JSON.parse(JSON.stringify(resp.data));
            const siderbarRouters = JSON.parse(JSON.stringify(resp.data));
            buildMenuTree(router);
            buildMenuTree(siderbarRouters);
            context.commit("SET_SIDERBAR_ROUTERS", siderbarRouters);
            buildMenu(router);
            buildRouter(router);
            context.commit("SET_ROUTER", router);
            resolve(router);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    Logout(context) {
      return new Promise((resolve, reject) => {
        logoutApi()
          .then(() => {
            context.commit("SET_TOKEN", "");
            context.commit("SET_ROLE", []);
            context.commit("SET_PERMISSION", []);
            removeToken();
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mutations: {
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_NICKNAME(state, nickname) {
      state.nickname = nickname;
    },
    SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_SIDERBAR_ROUTERS(state, siderbarRouters) {
      state.siderbarRouters = siderbarRouters;
    },
    SET_ROUTER(state, router) {
      state.router = router;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ROLE(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSION(state, permissions) {
      state.permissions = permissions;
    },
  },
};

export const buildMenu = (root) => {
  root.forEach((menu) => {
    if (menu.component === "LAYOUT") {
      menu.component = Layout;
    } else if (menu.component === "PARENT_LAYOUT") {
      menu.component = ParentLayout;
    } else {
      menu.component = loadView(menu.component);
    }

    if (menu.children && menu.children.length > 0) {
      buildMenu(menu.children);
    }
  });
};

export const buildMenuTree = (root) => {
  root.forEach((menu) => {
    if (menu.meta.link) {
      menu.meta.menuType = "M";
      if (menu.path.startsWith("/")) {
        menu.path = menu.path.slice(1);
      }
    }
    if (!menu.children) {
      menu.component = "LAYOUT";
      menu.path = "/" + menu.path;
    }
  });
};

export const buildRouter = (root) => {
  root.forEach((menu, i) => {
    if (!menu.children) {
      menu.children = [{ ...menu }];
      if (process.env.NODE_ENV === "development") {
        menu.children[0].component = (resolve) =>
          require([
            `@/views/${menu.children[0].path.replace("/", "")}`,
          ], resolve);
      } else {
        menu.children[0].component = () =>
          import(`@/views/${menu.children[0].path.replace("/", "")}`);
      }
      menu.redirect = menu.name;
      menu.name = "Layout" + i;
    }
  });
};

export const loadView = (view) => {
  if (process.env.NODE_ENV === "development") {
    // console.log(view);
    return (resolve) => require([`@/views/${view}`], resolve);
  } else {
    // 使用 import 实现生产环境的路由懒加载
    // console.log(view);
    return () => import(`@/views/${view}`);
  }
};
